<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('log_page.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('log_page.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input :label="$t('log_page.date_from')" :date="dateFrom" @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input :label="$t('log_page.date_to')" :date="dateTo" @setValue="dateTo = $event" />
            </span>
            <v-btn color="primary" class="" @click="searchByDate"> {{ $t('search') }} </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }} :</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="logs"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.url`]="{ item }">
              <span @click="linkTo(`${item.url}`)">
                <a>{{ item.url }}</a>
              </span>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      headers: [
        {
          text: this.$t('log_page.header1'),
          value: 'ip',
          width: '100px',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('log_page.header2'),
          value: 'name',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('log_page.header3'),
          value: 'url',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_page.header4'),
          value: 'detail',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_page.header5'),
          value: 'admin',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_page.header6'),
          value: 'created_at',
          align: 'center',
          sortable: false,
        },
      ],
      logs: [],
      search: '',
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          let result = []
          result = await this.getLogPageData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`)
          this.logs = result.data
          this.pagination.totalItems = result.count
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'Logs เปิดหน้าเว็บ',
            url: window.location.href,
            detail: 'รายการ Logs (เปิดหน้าเว็ป)',
            admin: userSystem.name ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async findByDate() {
      this.loading = true
      const params = `page=1&row=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`
      const result = await this.getLogPageData(params)
      this.pagination.totalItems = result.count
      this.logs = result.data
      this.loading = false
    },
    async getLogPageData(params) {
      try {
        return await this.$store.dispatch('getLogRequest', params)
      } catch (e) { }
    },
    linkTo(url) {
      window.open(url)
    },
    concatUrl(url, path) {
      return url ? `${url.substring(0, url.length - 1)}${path}` : path
    },
    async searchKeyword(value) {
      this.loading = true
      try {
        let result = {}
        if (value) {
          result = await this.getLogPageData(`page=1&rows=${this.pagination.rowsPerPage}&search=${value}`)
        } else {
          result = await this.getLogPageData(`page=1&rows=${this.pagination.rowsPerPage}`)
        }
        this.pagination.totalItems = result.count
        this.logs = result.data
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.loading = false
    },
    async searchByDate() {
      this.loading = true
      const result = await this.getLogPageData(
        `page=1&rows=${this.pagination.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`,
      )
      this.pagination.totalItems = result.count
      this.logs = result.data
      this.loading = false
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
